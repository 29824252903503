const TradingBot = () => {
  return (
    <div id="trading-bots" className="horizontal-padding bg-bg-color py-20 sm:py-32 md:py-0">
      <div className="flex justify-center">
        <div className="w-full max-w-[820px] text-center mb-10 md:mb-20">
          <h2 className=" text-lite-purple text-2xl md:text-4xl font-bold leading-[150%] mb-5 md:mb-9">
            Welcome to CREIT AI Advanced <span className="gradient-text">trading bots</span>
          </h2>
          <p className="text-pink-pearl text-xl font-normal leading-[150%]">
            CREIT will deploy over 300 Al infused bots utilizing several different trading strategies including: Grid trading , Trend
            following , Mean-revision and Momentum
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-full max-w-[1010px]">
          <div className="flex justify-center">
            <img src="/assets/trending-bot/bot.svg" alt="" className="" />
          </div>
          <div className="text-lite-purple text-base md:text-2xl font-medium md:text-center mt-10 lg:mt-20">
            <p className="mb-5 lg:mb-8">
              Our bots have been operational for over a year, yielding consistent double digit performance. CREIT will achieve at LEAST a
              very conservative <span className="apy-text text-lg md:text-2xl font-medium">24% APY,</span> with proven results coming in much higher.
            </p>
            <p>
              A distinct advantage to CREIT holders is that our bots will yield results regardless of bull/bear market sentiment. Paired
              with a solid Real Estate Portfolio, CREIT will be one of the highest rev share models in the crypto industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TradingBot
