import Footer from './components/Footer'
import Header from './components/Header'
import Platform from './components/Platform'
import RevenueModel from './components/RevenueModel'
import TradingBot from './components/TradingBot'
import Vision from './components/Vision'

function App() {
  return (
    <main className="">
      <Header />
      <Vision />
      <TradingBot />
      <Platform />
      <RevenueModel />
      <Footer />
    </main>
  )
}

export default App
