import React from 'react'
import './slideDownMenu.css'
import Button from '../Button'
import { WalletIcon } from '../../svgs'

interface Menu {
  menu: {
    name: string
    link: string
  }[]
  callback: (value: boolean) => void
}

const SlideDownMenu = ({ menu, callback }: Menu) => {
  return (
    <div className="menu">
      <div className="crossIcon">
        <div>
          <img src="/logo.svg" className="w-[48px] h-[50px] object-contain" alt="Creit" />
        </div>
        <span onClick={() => callback && callback(false)}>x</span>
      </div>
      <div className="flex-1 flex flex-col mt-5">
        {menu?.map((item) => (
          <a key={item.link} href={item.link} onClick={() => callback && callback(false)} className="menuLink">
            {item.name}
          </a>
        ))}
        <Button onClick={() => {}} className="flex items-center gap-4 !w-[150px] mt-6">
          Connect <WalletIcon />
        </Button>
      </div>
      <div className="horizontal-padding bg-[#1f003e] py-4 flex justify-between items-center">
        <p className="text-pink-pearl text-sm w-full text-center">Copyright © 2024 – CREIT | Terms & Conditions</p>
      </div>
    </div>
  )
}

export default SlideDownMenu
