import { Fragment } from 'react/jsx-runtime'
import { RadioIcon, TrackArrowUp, TrackArrowDown, TrackArrowLeft, TrackArrowRight } from '../svgs'

const RevenueModel = () => {
  return (
    <section id="rev-model" className="horizontal-padding bg-bg-color pb-28 lg:pb-40">
      <div className="relative py-[15%]">
        <h3 className="text-lite-purple text-[22px] tracking-[1.2px] lg:text-4xl font-bold leading-[150%] text-center pb-[50px] lg:pb-[100px]">
          Maximize Your Earnings: CREIT's Dynamic<span className="gradient-text"> Revenue Sharing Model </span>
        </h3>
        <div className="flex justify-center">
          <div className="hidden md:block w-[548px] h-[547px]">
            <img src="/assets/rev-model/image.svg" alt="creit revenue model" className="w-full h-full object-contain" />
          </div>
          <div className="md:hidden w-full h-[346px]">
            <img src="/assets/rev-model/image-sm.svg" alt="creit revenue model" className="w-full h-full object-contain" />
          </div>
        </div>
      </div>
      {/* Track */}
      <div className="md:px-[50px] mt-14">
        <h5 className="text-lite-purple text-2xl font-bold tracking-[0.88px]">Milestone Growth Tracker:</h5>
        <div className="text-pink-pearl text-xl leading-[180%] w-full 2xl:max-w-[88%] mt-6 mb-[90px]">
          {/* <p>
            Total market cap = <span className="font-bold">10,000,000 $</span>
          </p> */}
          <p>
            As market cap milestones are achieved, corresponding increases in revenue share percentages will be applied to reward our valued
            token holders even more generously. Taxes will be 5% on all buys/sells and will be used for various needs of the project.
          </p>
        </div>
        <div className="flex items-center justify-center md:justify-normal mb-3 md:mb-auto">
          <p className="text-lite-purple font-semibold lg:w-[90px]">Market cap Milestones</p>
          <div className="hidden h-[200px] md:flex flex-col justify-center w-full">
            <div className="relative bg-track-bg h-0.5 flex items-center">
              {TrackData.map((data, i) => (
                <Fragment key={data.id}>
                  {i % 2 === 0 ? (
                    <div className="w-[15%]">
                      <div className="track-arrow-up-transform absolute bottom-0 w-auto flex flex-col items-center">
                        <p className="text-white font-medium tracking-[0.64px]">{data.share}</p>
                        <TrackArrowUp color={data.color} />
                      </div>
                      <div className="relative">
                        <RadioIcon color={data.color} />
                        <span className="text-white text-[22px] font-bold absolute left-1 top-7">{data.number}</span>
                      </div>
                    </div>
                  ) : (
                    <div className="w-[15%]">
                      <div className="track-arrow-down-transform w-auto absolute top-0 flex flex-col items-center">
                        <TrackArrowDown color={data.color} />
                        <p className="text-white font-medium tracking-[0.64px]">{data.share}</p>
                      </div>
                      <div className="relative">
                        <RadioIcon color={data.color} />
                        <span className="text-white text-[22px] font-bold absolute left-1 top-7">{data.number}</span>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
              <div className="relative">
                <RadioIcon color="#F12EFA" />
                <span className="text-white text-[22px] font-bold absolute left-1 top-7">90M+</span>
              </div>
            </div>
          </div>
        </div>

        {/* Track sm */}
        <div className="flex justify-center md:hidden">
          <div className="h-[1168px] w-0.5 bg-track-bg-sm flex justify-center">
            <div>
              {TrackData.map((data, i) => (
                <Fragment key={data.id}>
                  {i % 2 === 0 ? (
                    <div className="relative h-[14%]">
                      <span className="text-white text-[22px] font-bold absolute -left-14 top-1">{data.number}</span>
                      <RadioIcon color={data.color} />
                      <div className="absolute left-3 top-[40%] w-[150px]">
                        <p className="text-white font-medium tracking-[0.64px] mb-2 ml-8">{data.share}</p>
                        <TrackArrowLeft color={data.color} />
                      </div>
                    </div>
                  ) : (
                    <div className="relative h-[14.1%]">
                      <span className="text-white text-[22px] font-bold absolute left-10 top-1">{data.number}</span>
                      <RadioIcon color={data.color} />
                      <div className="absolute  top-[50%] w-[240px] -right-[86px]">
                        <p className="text-white font-medium tracking-[0.64px] mb-2 mr-20">{data.share}</p>
                        <div className="absolute right-[98px]">
                          <TrackArrowRight color={data.color} />
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
              <div className="relative">
                <RadioIcon color="#F12EFA" />
                <span className="text-white text-[22px] font-bold absolute left-8 top-0">90M+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RevenueModel

const TrackData = [
  {
    id: 1,
    number: 0,
    share: '20% rev share',
    color: '#57C9E0',
  },
  {
    id: 2,
    number: '15M',
    share: '25% rev share',
    color: '#57C9E0',
  },
  {
    id: 3,
    number: '30M',
    share: '30% rev share',
    color: '#57C9E0',
  },
  {
    id: 4,
    number: '45M',
    share: '35% rev share',
    color: '#57C9E0',
  },
  {
    id: 5,
    number: '60M',
    share: '40% rev share',
    color: '#9A40EE',
  },
  {
    id: 6,
    number: "75M",
    share: '45% rev share',
    color: '#C637F4',
  },
  {
    id: 7,
    number: '90M',
    share: '50% rev share',
    color: '#F12EFA',
  },
]
