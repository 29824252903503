import { DiscordIcon, GithubIcon, InstagramIcon, LinkedInIcon, TelegramIcon, TwitterIcon } from '../svgs'

const Footer = () => {
  return (
    <section className="horizontal-padding bg-[#20002D] h-[100px] flex flex-col gap-5 justify-center lg:flex-row lg:justify-between items-center">
      <p className="text-pink-pearl text-sm">Copyright © 2024 – CREIT | Terms & Conditions</p>
      <div className="flex gap-5">
        {SocialLinks.map((social) => (
          <a href={social.link} key={social.id} className="mr-4">
            {social.icon}
          </a>
        ))}
      </div>
    </section>
  )
}

export default Footer

const SocialLinks = [
  {
    id: 1,
    link: '',
    icon: <DiscordIcon />,
  },
  {
    id: 2,
    link: '',
    icon: <InstagramIcon />,
  },
  {
    id: 3,
    link: '',
    icon: <LinkedInIcon />,
  },
  {
    id: 4,
    link: '',
    icon: <GithubIcon />,
  },
  {
    id: 5,
    link: '',
    icon: <TelegramIcon />,
  },
  {
    id: 6,
    link: '',
    icon: <TwitterIcon />,
  },
]
