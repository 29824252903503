const Vision = () => {
  return (
    <div id="vision">
      <div className="hidden lg:block relative horizontal-padding bg-bg-color h-screen">
        <div className="gradient-box absolute top-[20%] left-0" />
        <div className="vision-gradient absolute top-[30%] left-[65%] right-1" />
        <div className="flex justify-center items-center gap-8 h-full">
          <div className="flex justify-between items-center gap-4 h-full w-full max-w-[1400px]">
            <div className="w-full max-w-[538px] flex flex-col gap-9">
              <h3 className="text-lite-purple text-4xl font-bold leading-[150%]">
                CREIT uses an advanced AI trading algorithms to achieve{' '}
                <span className="gradient-text">consistent double digit returns</span>
              </h3>
              <p className="text-pink-pearl text-xl font-normal leading-[150%]">
                Along with our dynamic Real Estate Portfolio, the CREIT team has built some of the most advanced trading bots in the
                industry. Proven double digit gains over the last year, the CREIT team invest in a variety of Large/Medium/Small cap
                cryptocurrencies to reward CREIT holders with the highest rev share returns in the industry.
              </p>
            </div>
            <div>
              <div className="w-[400px] h-[400px] xl:w-[500px] xl:h-[500px]">
                <img src="/assets/vision/image.webp" alt="creit vision" className="w-full h-full object-contain" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* sm */}
      <div className="lg:hidden py-[70px] horizontal-padding bg-bg-color">
        <div className="md:px-[100px]">
          <h3 className="text-lite-purple text-2xl font-bold leading-[150%] mb-8">
            CREIT uses an advanced AI trading algorithms to achieve <span className="gradient-text">consistent double digit returns</span>
          </h3>
          <p className="text-pink-pearl text-lg font-normal leading-[150%]">
            Along with our dynamic real estate portfolio, the CREIT team has built some of the most advanced trading bots in the industry.
            Proven double digit gains over the last year, the CREIT team invest in a variety of large/medium/small cap cryptocurrencies to
            reward CREIT holders with a highest rev share returns in a industry.
          </p>
        </div>
        <div className="w-full h-[370px] px-2 mt-[60px]">
          <img src="/assets/vision/image.webp" alt="creit vision" className="w-full h-full object-contain" />
        </div>
      </div>
    </div>
  )
}

export default Vision
