type ButtonProps = {
  onClick: () => void
  children: React.ReactNode
  className?: string
}

const Button = ({ children, onClick, className }: ButtonProps) => {
  return (
    <button onClick={onClick} className={`button text-white py-4 px-8 text-[15px] font-medium ${className}`}>
      {children}
    </button>
  )
}

export default Button
