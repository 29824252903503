import { Fragment } from 'react/jsx-runtime'
import { PlatformContent } from '../utils/constants'

const Platform = () => {
  return (
    <section id="platform">
      <div className="hidden xl:block relative bg-bg-color horizontal-padding pt-[15%]">
        <div className="platform-gradient absolute top-[46%] right-1" />
        <div className="w-full max-w-[1300px] m-auto">
          <h3 className="text-lite-purple text-4xl font-bold leading-[150%] text-center pb-[100px]">
            Unlock the Future: Where <span className="gradient-text">Crypto Meets Real Estate</span> Perks
          </h3>
          <div className="bg-platform-bg bg-no-repeat bg-contain h-[1147px] flex flex-col items-end gap-[271px]">
            {PlatformContent.map((content) => (
              <div key={content.id} className="flex flex-col justify-center gap-2.5 w-full max-w-[488px] first:mt-20 last:mt-10">
                <h5 className="text-lite-purple text-xl font-bold leading-[153.521%]">{content.title}</h5>
                <p className="text-pink-pearl text-xl leading-[150%]">{content.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* sm */}
      <div className="horizontal-padding bg-bg-color xl:hidden pb-[100px]">
        <h3 className="text-lite-purple text-[22px] font-bold tracking-[1.2px] text-center mb-[100px]">
          Unlock the Future: Where <span className="gradient-text">Crypto Meets Real Estate</span> Perks
        </h3>
        <div className="flex justify-center">
          <div className="flex flex-col gap-[100px] w-full max-w-[400px]">
            {PlatformContent.map((content) => (
              <div key={content.id}>
                <div className="w-full h-auto mb-10">
                  <img src={content.image} alt={content.description} className="w-full h-full object-contain" />
                </div>
                <h5 className="text-lite-purple text-xl font-bold leading-[153.521%] mb-3">{content.title}</h5>
                <p className="text-pink-pearl text-xl leading-[150%]">{content.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Platform
