import Button from './Button'
import Navbar from './Navbar'

const Header = () => {
  return (
    <section id="home" className="horizontal-padding w-full h-screen bg-header-bg-sm bg-no-repeat md:bg-header-bg bg-cover bg-center">
      <Navbar />
      <div className="w-full flex flex-col gap-7 md:gap-9 items-center justify-center pt-16">
        <h1 className="text-2xl text-center md:text-4xl md:text-left text-lite-purple font-bold tracking-[1.2px] md:tracking-[1.8px] ">
          CREIT: Simplifying <span className="gradient-text">Crypto-Real Estate</span> Investments
        </h1>
        <h2 className="text-pink-pearl md:text-lg text-center tracking-[0.9px] leading-[140%] w-full lg:max-w-[700px]">
          Step into the future of investing. CREIT combines high-performing real estate dividends with the industry's leading trading bot,
          aiming to offer the best revenue share in crypto. Simplify your path to wealth.
        </h2>
        <div>
          <Button onClick={() => {}} className="py-3 px-5 md:py-4 md:px-8 ">
            Read Whitepaper
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Header
