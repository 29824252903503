import { Fragment } from 'react/jsx-runtime'
import { HamburgerIcon, WalletIcon } from '../svgs'
import { NavbarItems } from '../utils/constants'
import Button from './Button'
import SlideDownMenu from './SlideDownMenu'
import { useState } from 'react'

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <Fragment>
      <div className="hidden lg:flex gap-4 justify-between items-center py-6">
        <img src="/logo.svg" alt="Creit" className="w-[58px] h-[61px] object-contain" />
        <div className="flex gap-7 items-center">
          {NavbarItems.map((item, index) => (
            <a
              key={index}
              href={item.link}
              className="inter text-lite-purple text-lg font-medium capitalize hover:text-xl hover:animate-pulse hover:transition-all hover:duration-700 hover:ease-in-out"
            >
              {item.name}
            </a>
          ))}
        </div>
        <Button onClick={() => {}} className="flex items-center gap-4">
          Connect <WalletIcon />
        </Button>
      </div>
      <div className="flex justify-between items-center pt-7 lg:hidden">
        <img src="/logo.svg" alt="Creit" className="w-[48px] h-[50px] object-contain" />
        <HamburgerIcon onClick={() => setIsSidebarOpen(true)} />
      </div>
      {isSidebarOpen && <SlideDownMenu callback={() => setIsSidebarOpen(false)} menu={NavbarItems} />}
    </Fragment>
  )
}

export default Navbar
