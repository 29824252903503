export const NavbarItems = [
  {
    name: 'Home',
    link: '#home',
  },
  {
    name: 'Vision',
    link: '#vision',
  },
  {
    name:"Trading Bots",
    link:"#trading-bots"
  },
  {
    name: 'The Platform',
    link: '#platform',
  },
  {
    name: 'Rev Share Model',
    link: '#rev-model',
  },
]

export const PlatformContent = [
  {
    id: 1,
    title: 'Problem with Current Cryptocurrencies:',
    description: 'Many cryptocurrencies fail to offer real value or guarantees, leading to lost trust and disappointing investments.',
    image: '/assets/platform/sm-1.svg',
  },
  {
    id: 2,
    title: `CREIT's Unique Solution:`,
    description:
      'CREIT introduces a revolutionary Real Estate Portfolio offering tangible benefits and dual returns, boosting value and trust from day one.',
    image: '/assets/platform/sm-2.svg',
  },
  {
    id: 3,
    title: 'Exclusive Holiday Rentals:',
    description:
      'Benefit from special pricing on holiday rentals as a CREIT holder, enjoying unique advantages based on your investment level.',
    image: '/assets/platform/sm-3.svg',
  },
]
